<template>
  <div>
    <expense-list-add-new
      :is-add-new-expense-sidebar-active.sync="isAddNewExpenseSidebarActive"
      :type-options="typeOptions"
      :payment-options="paymentOptions"
    />

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div
              v-bind:class="{
                'align-items-center justify-content-end': isActive,
                'd-flex align-items-center justify-content-end': !isActive,
              }"
            >
              <b-input-group class="mr-1">
                <b-form-input
                  v-model="searchQuery"
                  @keyup.enter="searchWord()"
                  :placeholder="$t('message.bookmark.search')"
                />
                <b-input-group-append>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                    @click="searchWord()"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <div>
                <flat-pickr
                  v-model="monthSelected"
                  class="form-control"
                  :config="flatConfig"
                  :placeholder="$t('message.calendar.date')"
                />
              </div>

              <div>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon"
                  @click="monthSelected = ''"
                >
                  <feather-icon icon="RefreshCcwIcon" />
                </b-button>
              </div>

              <br v-if="isActive" />
              <b-dropdown
                right
                variant="gradient-warning"
                class="mr-1 ml-1"
                v-if="restrictions('button_export_data')"
              >
                <template slot="button-content">
                  <feather-icon icon="DownloadIcon" />
                </template>
                <b-dropdown-item
                  ><download-excel
                    :data="expenseList"
                    :before-generate="startDownload"
                    :before-finish="finishDownload"
                    :name="$t('message.buttons.dataInView')"
                  >
                    {{ $t("message.buttons.dataInView") }}
                  </download-excel></b-dropdown-item
                >
                <b-dropdown-item
                  ><download-excel
                    :fetch="allData"
                    :before-generate="startDownload"
                    :before-finish="finishDownload"
                    :name="$t('message.buttons.allData')"
                  >
                    {{ $t("message.buttons.allData") }}
                  </download-excel></b-dropdown-item
                >
              </b-dropdown>

              <b-button
                variant="primary"
                @click="isAddNewExpenseSidebarActive = true"
                v-if="restrictions('button_new_expense')"
              >
                <span class="text-nowrap">{{
                  $t("message.expenses.newExpenses")
                }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        id="my-table"
        ref="refexpenseListTable"
        class="position-relative"
        :items="expenseList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :busy="isBusy"
        show-empty
        @row-clicked="openViewPage"
        hover
      >
        <template #table-busy>
          <div class="text-center my-17">
            <b-spinner class="align-middle"></b-spinner>
            <strong> {{ $t("message.load") }} </strong>
          </div>
        </template>

        <template slot="empty">
          <div class="text-center my-5">
            {{ $t("message.registerNotFound") }}
          </div>
        </template>

        <!-- Column: Date -->
        <template #cell(Data)="data">
          {{ localeDate(data.item.date) }}
        </template>

        <!-- Column: Description -->
        <template #cell(Descrição)="data">
          <span v-html="data.item.description"></span>
        </template>

        <!-- Column: Type -->
        <template #cell(Tipo)="data">
          {{ data.item.type }}
        </template>

        <!-- Column: Value -->
        <template #cell(Valor)="data">
          {{ $n(data.item.value, "currency") }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t("message.pagelength") }} {{ dataMeta.from }}
              {{ $t("message.of") }} {{ dataMeta.to }} {{ $t("message.of") }}
              {{ totalRegisters }} {{ $t("register") }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            v-if="showPagination"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRegisters"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
              @input="eventPage(currentPage)"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useexpensesList from "./useExpensesList";
import ExpenseListAddNew from "./ExpenseListAddNew.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import JsonExcel from "vue-json-excel";
import Vue from "vue";
import AccessControl from "@core/utils/access-control";
import ListWidth from "@core/utils/listWidth";
import flatPickr from "vue-flatpickr-component";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
require("flatpickr/dist/plugins/monthSelect/style.css");
Vue.component("downloadExcel", JsonExcel);
import store from "@/store";

export default {
  components: {
    ExpenseListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BSpinner,
    flatPickr,
    monthSelectPlugin,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      expenseList: [],
      totalRegisters: 0,
      perPage: 0,
      currentPage: 1,
      isBusy: false,
      showPagination: true,
      allDataSize: 9999,

      //Select
      paymentOptions: [],
      typeOptions: [],

      monthSelected: "",

      //Param
      paramData: store.state.user.paramData,

      userData: store.state.user.userData,

      //Resize
      windowWidth: window.innerWidth,
      isActive: true,

      flatConfig: {
        locale:
          this.$i18n.locale !== "en"
            ? require(`flatpickr/dist/l10n/${this.$i18n.locale}.js`).default[
                this.$i18n.locale
              ]
            : "en",
        static: true,
        altInput: true,
        plugins: [
          new monthSelectPlugin({
            shorthand: false,
            dateFormat: "Y-m-d",
            altFormat: "M Y",
          }),
        ],
      },
    };
  },

  computed: {
    tableColumns() {
      const lang = this.$i18n.locale;
      if (!lang) {
        return [];
      }

      return [
        { key: "Data", sortable: false, label: this.getColLabel("date") },
        {
          key: "Descrição",
          sortable: false,
          label: this.getColLabel("description"),
        },
        { key: "Tipo", sortable: false, label: this.getColLabel("type") },
        { key: "Valor", sortable: false, label: this.getColLabel("value") },
      ];
    },
  },

  async created() {
    this.onResize();
    this.getExpenseList();

    var paymentList = [];
    await axios
      .get(`${URL_API}expense_payment`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].type;
          paymentList.push(element);
          this.paymentOptions.push({
            label: paymentList[i],
            value: paymentList[i],
          });
        }
      });

    var typeList = [];
    await axios
      .get(`${URL_API}expense_type`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].type;
          typeList.push(element);
          this.typeOptions.push({ label: typeList[i], value: typeList[i] });
        }
      });
  },

  mounted() {
    this.$root.$on("newListExpense", (newListExpense) => {
      this.monthSelected = "";
      this.isBusy = true;

      axios
        .get(
          `${URL_API}expense/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          this.expenseList = response.data.content;
          this.totalRegisters = response.data.totalElements;
          this.perPage = response.data.numberOfElements;
          this.currentPage = 1;
          this.isBusy = false;
        });
    });
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  watch: {
    monthSelected: function () {
      this.monthSelectedFilter();
    },
  },

  methods: {
    async allData() {
      const response = await axios.get(
        `${URL_API}expense/list?page=0&sort=id,${this.paramData.listSort}&size=${this.allDataSize}`,
        { headers: getHeader(this.userData) }
      );
      return response.data.content;
    },

    async monthSelectedFilter() {
      this.expenseList = [];

      if (this.monthSelected != "") {
        const split = this.monthSelected.split("-");

        var year = split[0];
        var month = split[1];

        this.isBusy = true;
        await axios
          .get(`${URL_API}expense/${year}/${month}`, {
            headers: getHeader(this.userData),
          })
          .then((response) => {
            this.expenseList = response.data;
            this.isBusy = false;
          });
      } else {
        this.getExpenseList();
      }
    },

    restrictions(value) {
      return AccessControl(value);
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      this.isActive = ListWidth(this.windowWidth);
    },

    getColLabel(colName) {
      return this.$i18n.t("message.list." + colName);
    },

    startDownload() {
      this.isBusy = true;
    },

    finishDownload() {
      this.isBusy = false;
    },

    openViewPage(row) {
      row._showDetails = !row._showDetails;
      this.$router.push({ name: "apps-expense-view", params: { id: row.id } });
    },

    async getExpenseList() {
      this.isBusy = true;
      await axios
        .get(
          `${URL_API}expense/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          this.totalRegisters = response.data.totalElements;
          this.expenseList = response.data.content;
          this.perPage = response.data.numberOfElements;
          this.isBusy = false;
        });

      if (this.expenseList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
        this.currentPage = 1;
      }
    },

    async eventPage(currentPage) {
      this.isBusy = true;
      await axios
        .get(
          `${URL_API}expense/list-dto?page=${currentPage - 1}&sort=id,${
            this.paramData.listSort
          }&size=${this.paramData.tableSize}`,
          { headers: getHeader(this.userData) }
        )
        .then((response) => {
          this.expenseList = response.data.content;
          this.isBusy = false;
        });

      if (this.expenseList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
      }
    },

    async getExpenseFilter() {
      this.isBusy = true;
      await axios
        .get(`${URL_API}expense/search?searchTerm=${this.searchQuery}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.expenseList = [];
          if (response.data != []) {
            for (var i = 0; i < response.data.length; i++) {
              this.expenseList.push(response.data[i]);
            }
          }
          this.isBusy = false;
          this.showPagination = false;
        });
    },

    searchWord() {
      this.monthSelected = "";
      if (this.searchQuery) {
        this.getExpenseFilter();
      } else {
        this.getExpenseList();
      }
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },
  },

  setup() {
    const isAddNewExpenseSidebarActive = ref(false);

    const {
      perPage,
      currentPage,
      totalexpenses,
      dataMeta,
      searchQuery,
      refexpenseListTable,
    } = useexpensesList();

    return {
      isAddNewExpenseSidebarActive,
      perPage,
      currentPage,
      totalexpenses,
      dataMeta,
      searchQuery,
      refexpenseListTable,
      avatarText,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.my-17 {
  margin-top: 17.2rem;
  margin-bottom: 17.2rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "https://cdn.jsdelivr.net/npm/flatpickr@latest/dist/plugins/monthSelect/style.css";
</style>
