/* eslint-disable */
import { ref, watch, computed } from "@vue/composition-api";
export default function useexpensesList() {
  const refexpenseListTable = ref(null);

  const perPage = ref(10);
  const totalexpenses = ref(0);
  const currentPage = ref(1);

  const dataMeta = computed(() => {
    const localItemsCount = refexpenseListTable.value
      ? refexpenseListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalexpenses.value,
    };
  });

  return {
    perPage,
    currentPage,
    totalexpenses,
    dataMeta,
    refexpenseListTable,
  };
}
